import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Index } from "app/containers/sign";
import { Index as IndexNotFound } from "app/containers/index/not-found";

export default class CustomRouter extends React.PureComponent {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="*" element={<IndexNotFound />} />
        </Routes>
      </BrowserRouter>
    );
  }
}
