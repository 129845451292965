import { isMobile } from "react-device-detect";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";

import { AlertError } from "app/components/alerts/AlertError";
import { AlertPending } from "app/components/alerts/AlertPending";
import { AlertSuccess } from "app/components/alerts/AlertSuccess";
import { Button } from "app/components/buttons/Button";
import { QRCode } from "./QRCode";

interface Props {
  hasStarted: boolean;
  isSignSuccess: null | boolean;
  title: string;
  description: string;
  buttonContinueLabel: string;
  buttonResetLabel: string;
  redirectUrl?: string;
  onContinue: () => void;
  onReset: () => void;
  children: any;
  qrCodeLink?: string;
  instruction?: boolean;
  autoStartToken?: string;
}

export const StatusContainer = ({
  hasStarted,
  isSignSuccess,
  title,
  description,
  buttonContinueLabel,
  buttonResetLabel,
  redirectUrl,
  onContinue,
  onReset,
  children,
  qrCodeLink,
  instruction,
  autoStartToken,
}: Props) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [showOnThis, setShowOnThis] = useState(false);

  if (!hasStarted) {
    return children;
  }

  if (redirectUrl) {
    window.location.href = redirectUrl;
  }

  let showQrCode = (qrCodeLink && !isMobile) || showOnThis
  return (
    <>
      {isSignSuccess === null ? (
        <>
          {showQrCode && <QRCode qrCodeLink={qrCodeLink} shutdown={false} />}
          <AlertPending
            title={title}
            description={description}
            instruction={instruction}
            hasQrCode={qrCodeLink != null}
          />
          <Box
            sx={{
              paddingTop: "8px",
              fontWeight: "bold",
              textAlign: "center",
            }}
            hidden={!isMobile}
            component={"a"}
            ref={ref}
            target={"_blank"}
            rel={"noopener,noreferrer"}
            href={`https://app.bankid.com/?autostarttoken=${autoStartToken}&redirect=null`}
          >
            {t("BankIDDoesNotOpen")}
          </Box>
          <Box
            sx={{
              paddingTop: "8px",
              fontWeight: "bold",

              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() => {
              setShowOnThis(!showOnThis);
            }}
            hidden={!isMobile}
          >
            {t("ShowQrCodeOnThis")}
          </Box>
        </>
      ) : (
        <>
          {isSignSuccess === true ? (
            <>
              <AlertSuccess title={title} description={description} />
              <Button onClick={onContinue} label={buttonContinueLabel} />
            </>
          ) : (
            <>
              <AlertError title={title} description={description} />
              <Button onClick={onReset} label={buttonResetLabel} />
            </>
          )}
        </>
      )}
    </>
  );
};
