import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import { svSE } from "@mui/material/locale";

import { config } from "./config";

export const createSiteTheme = (mode) => {
  let configFonts = [];
  if (config.theme.fonts) {
    configFonts.push(config.theme.fonts);
  }
  const defaultFonts = ["Source Sans Pro", "sans-serif"];
  const fonts = configFonts.concat(defaultFonts);

  return responsiveFontSizes(
    createTheme(
      {
        palette: {
          mode,
          ...(mode === "light"
            ? {
                primary: {
                  main: config.theme.palettePrimaryColor,
                  contrastText: "#fff",
                },
                secondary: {
                  main: "#fff",
                  light: grey[300],
                  contrastText: grey[500],
                },
                text: {
                  primary: config.theme.palettePrimaryColor,
                },
                background: {
                  default: grey[100],
                },
              }
            : {
                primary: {
                  main: config.theme.palettePrimaryColor,
                  contrastText: "#fff",
                },
                secondary: {
                  main: grey[800],
                  light: grey[800],
                  contrastText: grey[600],
                },
                text: {
                  primary: config.theme.palettePrimaryColor,
                },
                background: {},
              }),
        },
        typography: {
          fontFamily: fonts.join(","),
          fontSize: 16,
        },
      },
      svSE
    )
  );
};
