import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";

import { store } from "./store/store";
import Router from "./app/router";
import "./locale/i18n";

import "./style/index.css";

render(
  <Provider store={store}>
    <Router />
  </Provider>,
  document.getElementById("root-wrapper")
);
