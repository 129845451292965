import i18n from "i18next";

import { defaultFallbackLng } from "config/config";
import { resources } from "locale/i18n";

export const getLocalesList = () => {
  return Object.keys(resources).map((key) => {
    return {
      code: key,
      label: resources[key].label,
    };
  });
};

export const getSelectedLocaleLabel = () => {
  if (Object.prototype.hasOwnProperty.call(resources, i18n.language)) {
    return resources[i18n.language].label;
  } else {
    return resources[defaultFallbackLng].label;
  }
};
