import apisauce from "apisauce";

import { config } from "config/config";

const createApis = () => {
  return {
    backend: apisauce.create({
      baseURL: config.backendUrl,
    }),
  };
};

export const Api = createApis();
