import { SignStatuses } from "./types";

export enum signStatuses {
  outstandingTransaction = "OUTSTANDING_TRANSACTION",
  noClient = "NO_CLIENT",
  started = "STARTED",
  userSign = "USER_SIGN",
  userReq = "USER_REQ",
  complete = "COMPLETE",
  communicationError = "COMMUNICATION_ERROR",
  failed = "FAILED",
  cancelled = "CANCELLED",
  initializeFailed = "INITIALIZE_FAILED",
  alreadyInProgress = "ALREADY_IN_PROGRESS",
  internalError = "INTERNAL_ERROR",
  startFailed = "START_FAILED",
}

export const signStatusToIsSignSuccessMap: SignStatuses = {
  [signStatuses.outstandingTransaction]: null,
  [signStatuses.noClient]: null,
  [signStatuses.started]: null,
  [signStatuses.userSign]: null,
  [signStatuses.userReq]: null,
  [signStatuses.complete]: true,
  [signStatuses.communicationError]: false,
  [signStatuses.failed]: false,
  [signStatuses.cancelled]: false,
  [signStatuses.initializeFailed]: false,
  [signStatuses.alreadyInProgress]: false,
  [signStatuses.internalError]: false,
  [signStatuses.startFailed]: false,
};
