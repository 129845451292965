import React from "react";
import { Button as MuiButton, CircularProgress } from "@mui/material";

interface Props {
  label: string;
  type?: "button" | "submit" | "reset";
  onClick?: any;
  color?: any;
  variant?: any;
  disabled?: boolean;
  loading?: boolean;
  sx?: any;
}

export const Button = ({
  label,
  type,
  onClick,
  color,
  variant,
  disabled,
  loading,
  sx,
}: Props) => {
  return (
    <MuiButton
      type={type ? type : "button"}
      endIcon={loading && <CircularProgress size={20} />}
      variant={variant ? variant : "contained"}
      color={color ? color : "primary"}
      fullWidth={true}
      sx={{
        p: 1,
        mt: 1,
        borderRadius: 2,
        textTransform: "none",
        ...sx,
      }}
      onClick={onClick}
      disabled={disabled || loading}
    >
      {label}
    </MuiButton>
  );
};
