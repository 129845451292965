import { createAsyncThunk } from "@reduxjs/toolkit";

const serviceName = "signing";

export const setTheme = createAsyncThunk<string, string>(
  `${serviceName}/setTheme`,
  (data, thunkAPI) => {
    return data;
  }
);
