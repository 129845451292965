import { Controller, useForm } from "react-hook-form";
import { Box, TextField } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";

import { StyledTextField } from "./StyledTextField";
import { Button } from "app/components/buttons/Button";

interface Props {
  fieldValue: string;
  fieldLabel: string;
  fieldPlaceholder: string;
  buttonSignLabel: string;
  hidePnrField?: boolean;
  onSign: (values: any) => void;
  schema: any;
}

export const SignForm = ({
  fieldValue,
  fieldLabel,
  fieldPlaceholder,
  buttonSignLabel,
  hidePnrField,
  onSign,
  schema,
}: Props) => {
  const { handleSubmit, control } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      id: fieldValue ? fieldValue : null,
    },
    resolver: yupResolver(schema),
  });
  return (
    <form onSubmit={handleSubmit(onSign)}>
      <Box sx={{ mb: 2 }}>
        {!fieldValue && hidePnrField ? (
          <></>
        ) : (
          <Controller
            name={"id"}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <StyledTextField
                id="id"
                type="text"
                label={fieldLabel}
                value={value ? value : ""}
                onChange={onChange}
                error={!!error}
                margin="dense"
                placeholder={fieldPlaceholder}
                disabled={fieldValue !== null}
                fullWidth
              />
            )}
          />
        )}
      </Box>
      <Button label={buttonSignLabel} type="submit" />
    </form>
  );
};
