import { Container, useTheme } from "@mui/material";
import * as yup from "yup";

import { Button } from "app/components/buttons/Button";
import { BankIdSeLogo, BankIdSeLogoWhite } from "./logos";
import { LogoContainer } from "./subcomponents/LogoContainer";
import { SignForm } from "./subcomponents/SignForm";

interface Props {
  fieldValue: string;
  fieldLabel: string;
  fieldPlaceholder: string;
  buttonSignLabel: string;
  buttonCancelLabel: string;
  onSign: (values: any) => void;
  onCancel: (values: any) => void;
}

const schema = yup.object({
  id: yup.string().notRequired().nullable(),
});

export const BankIdSweden = ({
  fieldValue,
  fieldLabel,
  fieldPlaceholder,
  buttonSignLabel,
  buttonCancelLabel,
  onSign,
  onCancel,
}: Props) => {
  const isLightTheme = useTheme().palette.mode === "light";
  return (
    <Container maxWidth="xs" sx={{ textAlign: "center" }}>
      <LogoContainer>
        {isLightTheme ? <BankIdSeLogo /> : <BankIdSeLogoWhite />}
      </LogoContainer>
      <SignForm
        fieldValue={fieldValue}
        fieldLabel={fieldLabel}
        fieldPlaceholder={fieldPlaceholder}
        buttonSignLabel={buttonSignLabel}
        hidePnrField={true}
        onSign={onSign}
        schema={schema}
      />
      <Button label={buttonCancelLabel} onClick={onCancel} variant="outlined" />
    </Container>
  );
};
