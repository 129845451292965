import { CircularProgress } from "@mui/material";
import { isMobile } from "react-device-detect";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";

import { Alert } from "./Alert";

interface Props {
  title: string;
  description?: string;
  instruction?: boolean;
  hasQrCode?: boolean;
}

export const AlertPending = ({
  title,
  description,
  instruction,
  hasQrCode,
}: Props) => {
  const { t } = useTranslation();

  if (hasQrCode && !isMobile && instruction) {
    return (
      <Box
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          fontWeight: "bold",
          fontSize: "20px",
          textAlign: "center",
          "& p": {
            margin: "6px 0",
          },
        }}
      >
        <Box
          sx={{
            fontSize: "25px",
          }}
        >
          {t("Instructions")}
        </Box>
        <p>1. {t("BankIDStart1")}</p>
        <p>2. {t("BankIDStart2")}</p>
        <p>3. {t("BankIDStart3")}</p>
      </Box>
    );
  }

  return (
    <Alert
      title={title}
      description={description}
      icon={<CircularProgress sx={{ color: "text.primary", fontSize: 60 }} />}
    />
  );
};
