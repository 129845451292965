import { Paper } from "@mui/material";

import { Children } from "types/globals";

interface Props {
  children: Children;
}

export const ContentContainer = ({ children }: Props) => {
  return (
    <Paper
      sx={{
        p: 4,
        mb: 3,
        borderRadius: 3,
      }}
    >
      {children}
    </Paper>
  );
};
