import { Container } from "@mui/material";
import { useState } from "react";
import * as yup from "yup";

import { Button } from "../buttons/Button";
import { Signature, SignatureCanvas } from "./subcomponents/SignatureCanvas";
import { SignFormSignature } from "./subcomponents/SignFormSignature";
import { FieldWrapper } from "./subcomponents/FieldWrapper";

interface Props {
  fieldFirstnameLabel: string;
  fieldLastnameLabel: string;
  fieldSignatureLabel: string;
  fieldSignatureHelpLabel: string;
  fieldSignatureOnEmptyLabel: string;
  buttonCancelLabel: string;
  buttonClearLabel: string;
  buttonChooseSignatureLabel: string;
  buttonSignLabel: string;
  onSign: any;
  onCancel: any;
}

interface SignFormValues {
  firstName: string;
  lastName: string;
}

export const Handsign = ({
  fieldFirstnameLabel,
  fieldLastnameLabel,
  fieldSignatureLabel,
  fieldSignatureHelpLabel,
  fieldSignatureOnEmptyLabel,
  buttonCancelLabel,
  buttonClearLabel,
  buttonChooseSignatureLabel,
  buttonSignLabel,
  onSign,
  onCancel,
}: Props) => {
  const [signatures, setSignatures] = useState<Signature[]>(null);

  const onSignHandler = (values: SignFormValues) => {
    onSign({
      signatures: signatures,
      firstName: values.firstName,
      lastName: values.lastName,
    });
  };

  const fields = [
    {
      id: "firstName",
      label: fieldFirstnameLabel,
      placeholder: null,
    },
    {
      id: "lastName",
      label: fieldLastnameLabel,
      placeholder: null,
    },
  ];

  const schema = yup
    .object({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
    })
    .required();

  return (
    <Container sx={{ textAlign: "center" }}>
      <SignFormSignature
        fields={fields}
        buttonSignLabel={buttonSignLabel}
        onSign={onSignHandler}
        isSignDisabled={signatures === null}
        schema={schema}
      >
        <FieldWrapper title={fieldSignatureLabel}>
          <SignatureCanvas
            signatures={signatures}
            setSignatures={setSignatures}
            labelSignatureRequired={fieldSignatureHelpLabel}
            labelSignatureMissing={fieldSignatureOnEmptyLabel}
            buttonClearLabel={buttonClearLabel}
            buttonChooseSignatureLabel={buttonChooseSignatureLabel}
          />
        </FieldWrapper>
      </SignFormSignature>
      <Button label={buttonCancelLabel} onClick={onCancel} variant="outlined" />
    </Container>
  );
};
