export const isLocalhost = () => {
  return (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  );
};

export const getSubdomain = () => {
  return window.location.host.split(".")[0];
};

export const getDomain = () => {
  // returns 'example.com' if the url has a subdomain, e.g 'subdomain.example.com', else null
  if (isLocalhost()) {
    return null;
  } else {
    const url = window.location.hostname;
    return url.split(".").slice(1).join(".");
  }
};

export const getQueryParams = (location) => {
  const params = location.search;
  return Object.fromEntries(new URLSearchParams(params));
};

export const encodeQueryParams = (data) => {
  const ret = [];
  for (const d in data) {
    if (Object.prototype.hasOwnProperty.call(data, d)) {
      ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
    }
  }
  return ret.join("&");
};
