import { Alert, Box, Container, Grid } from "@mui/material";
import { useRef, useState } from "react";
import SignaturePad from "react-signature-pad-wrapper";
import trimCanvas from "trim-canvas";

import { Button } from "app/components/buttons/Button";

interface Props {
  signatures: any;
  setSignatures: any;
  labelSignatureRequired: string;
  labelSignatureMissing: string;
  buttonClearLabel: string;
  buttonChooseSignatureLabel: string;
}

export interface Signature {
  filetype: string;
  filedata: string;
}

export const SignatureCanvas = ({
  signatures,
  setSignatures,
  labelSignatureRequired,
  labelSignatureMissing,
  buttonClearLabel,
  buttonChooseSignatureLabel,
}: Props) => {
  const [error, setError] = useState<boolean>(null);
  const canvasRef = useRef(null);

  const onClear = () => {
    setError(null);
    if (signatures) {
      setSignatures(null);
    } else {
      canvasRef.current.clear();
      canvasRef.current.handleResize();
    }
  };

  const onSave = () => {
    let canvas = canvasRef.current.canvasRef.current;
    const imageSVG = getImageFromCanvas(canvasRef, "image/svg+xml");

    if (imageSVG === "") {
      setError(true);
      return;
    }

    trimCanvas(canvas);
    const imagePNG = getImageFromCanvas(canvasRef, "image/png");

    if (imagePNG === "") {
      setError(true);
      return;
    }

    setSignatures([
      {
        filetype: "PNG",
        filedata: imagePNG,
      },
      {
        filetype: "SVG",
        filedata: imageSVG,
      },
    ]);
  };

  const getImageFromCanvas = (canvasRef, imageType): string => {
    if (canvasRef.current.isEmpty()) {
      return "";
    }
    const dataURL = canvasRef.current.toDataURL(imageType);
    const strToRemove = `data:${imageType};base64,`;
    return dataURL.slice(strToRemove.length);
  };

  const getSignature = (filetype): Signature => {
    let signature = {
      filetype: null,
      filedata: null,
    };
    signatures?.forEach((s) => {
      if (s.filetype.toLowerCase() === filetype.toLowerCase()) {
        signature = s;
      }
    });
    return signature;
  };

  return (
    <Box sx={{ mb: 2 }}>
      {signatures == null ? (
        <>
          <Box
            sx={{
              border: "1px solid #ccc",
              borderRadius: 2,
            }}
          >
            <SignaturePad
              ref={canvasRef}
              options={{
                penColor: "black",
              }}
            />
          </Box>
          <Box sx={{ mt: 1 }}>
            {error ? (
              <Alert severity="error">{labelSignatureMissing}</Alert>
            ) : (
              <Alert severity="info">{labelSignatureRequired}</Alert>
            )}
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                label={buttonClearLabel}
                onClick={onClear}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <Button label={buttonChooseSignatureLabel} onClick={onSave} />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Container sx={{ my: 3, textAlign: "center" }}>
            <img
              src={`data:image/png;base64,${getSignature("png").filedata}`}
              alt="Signature"
              style={{
                maxWidth: "100%",
              }}
            />
          </Container>
          <Button
            label={buttonClearLabel}
            onClick={onClear}
            variant="outlined"
          />
        </>
      )}
    </Box>
  );
};
