import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {useCallback, useEffect, useState} from "react";

import { SignMethod, SignMethods } from "app/containers/sign/types";
import {
  reset,
  signRequestStart,
  signRequestPoll,
  signRequestGetSignature,
} from "store/signing/services";
import { signingSelector } from "store/signing/selectors";
import { isSignSuccessful, useInterval } from "app/containers/sign/utils";
import { FrejaEId } from "app/components/signing/FrejaEId";
import { BankIdSweden } from "app/components/signing/BankIdSweden";
import { BankIdNorway } from "app/components/signing/BankIdNorway";
import { SignStatus } from "store/signing/types";
import { Handsign } from "app/components/signing/Handsign";
import { Signature } from "app/components/signing/subcomponents/SignatureCanvas";
import { StatusContainer } from "app/components/signing/subcomponents/StatusContainer";
import {signStatuses} from "../../../../store/signing/constants";
import {isMobile} from "react-device-detect";

interface Props {
  uuid: string;
  method: SignMethod;
  redirected: boolean;
}

type SignStatusTitles = {
  title: string;
  description: string;
  instruction:boolean;
};

type SignValues = {
  id?: string;
  signatures?: Signature[];
  firstName: string;
  lastName: string;
};

export const SignAppContainer = ({ uuid, method, redirected }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [autoStart, setAutoStart] = useState(false)

  const signingState = useSelector(signingSelector);
  const signRequestData = signingState.actionState.get.payload;
  const qrCodeLink = signingState.actionState.sign.payload?.qrCodeLink;


  const onCancel = () => {
    window.location.assign(signRequestData.client_config.redirectUrl);
  };

  const onSign = async (values: SignValues) => {
    const signRequest = {
      params: {
        uuid: uuid,
      },
      body: {
        providerId: method,
        ssn: values?.id || null,
        signatures: values?.signatures || null,
        firstName: values?.firstName || null,
        lastName: values?.lastName || null,
        redirectUrl: `${window.location.href}&redirected=true`,
      },
    };
    await dispatch(signRequestStart(signRequest));

    let autoStartToken = signingState.actionState.sign.payload?.operationToken?.autoStartToken;
    if (autoStartToken != null && isMobile && method === SignMethods.BANKID) {
      window.open(`https://app.bankid.com/?autostarttoken=${autoStartToken}&redirect=null`, '_blank');
      setAutoStart(true);
    }

  };

  const onPoll = () => {
    const data = {
      uuid: uuid,
      orderRef:
        signingState.actionState.sign.payload?.operationToken?.orderRef || null,
    };
    dispatch(signRequestPoll(data));
  };

  const onGetSignature = useCallback(() => {
    const data = {
      uuid: uuid,
    };
    dispatch(signRequestGetSignature(data));
  }, [dispatch, uuid]);

  const onReset = () => {
    dispatch(reset());
  };

  const onContinue = () => {
    window.location.assign(signRequestData.client_config.redirectUrl);
  };

  if (
    signRequestData.client_config.redirectOnSuccess &&
    isSignSuccessful(signingState.customState.signStatus)
  ) {
    window.location.assign(signRequestData.client_config.redirectUrl);
  }

  const getSignStatusTitles = (
    isSignSuccess: boolean,
    signStatus: SignStatus
  ): SignStatusTitles => {
    let title = t(`containers.sign.statusTitles.${signStatus}.title`);
    let description = t(`containers.sign.statusTitles.${signStatus}.desc`, "");

    if (isSignSuccess === true) {
      if (signRequestData.client_config.onSuccessTitle) {
        title = signRequestData.client_config.onSuccessTitle;
      }
      if (signRequestData.client_config.onSuccessDescription) {
        description = signRequestData.client_config.onSuccessDescription;
      }
    }

    return {
      title: title,
      description: description,
      instruction:
        signStatus === signStatuses.outstandingTransaction ||
        signStatus === signStatuses.started,
    };
  };

  const signTitles: SignStatusTitles = getSignStatusTitles(
    isSignSuccessful(signingState.customState.signStatus),
    signingState.customState.signStatus
  );

  const shouldPoll = (): boolean => {
    if (method === SignMethods.HANDSIGN) {
      return false;
    }
    if (signingState.actionState.poll.meta.isLoading === true) {
      return false;
    }
    if (signingState.actionState.sign.meta.isLoading === false) {
      if (isSignSuccessful(signingState.customState.signStatus) === null) {
        return true;
      }
    }
  };

  const shouldGetSignature = (): boolean => {
    if (!redirected) {
      return false;
    }
    if (signingState.actionState.getSignature.meta.isLoading === true) {
      return false;
    }
    if (signingState.actionState.getSignature.meta.isLoading === false) {
      if (isSignSuccessful(signingState.customState.signStatus) === null) {
        return true;
      }
    }
  };

  useEffect(() => {
    let autoStartToken = signingState.actionState.sign.payload?.operationToken?.autoStartToken;

    if (!autoStart && autoStartToken != null) {
      if (isMobile && method === SignMethods.BANKID) {

        window.open(`https://app.bankid.com/?autostarttoken=${autoStartToken}&redirect=null`, '_blank');
        setAutoStart(true)
      }
    }
  }, [autoStart, method, signingState]);

  useEffect(() => {
    if (redirected) {
      onGetSignature();
    }
  }, [redirected, onGetSignature]);

  useInterval(
    () => {
      if (redirected) {
        onGetSignature();
      }
    },
    shouldGetSignature() ? 1000 : null
  );

  useInterval(
    () => {
      if (method === SignMethods.BANKID || method === SignMethods.FREJAEID) {
        onPoll();
      }
    },
    shouldPoll() ? 1000 : null
  );

  const methodLabel = t(`containers.sign.methods.${method}`);

  return (
    <>
      {method === SignMethods.BANKID && (
        <StatusContainer
          hasStarted={signingState.customState.signStatus !== null}
          isSignSuccess={isSignSuccessful(signingState.customState.signStatus)}
          title={signTitles.title}
          description={signTitles.description}
          buttonContinueLabel={t("containers.sign.buttons.continue")}
          buttonResetLabel={t("containers.sign.buttons.tryAgain")}
          onContinue={onContinue}
          onReset={onReset}
          qrCodeLink={qrCodeLink && qrCodeLink.length > 1 ? qrCodeLink : null}
          autoStartToken={signingState.actionState.sign.payload?.operationToken?.autoStartToken}
          instruction={signTitles.instruction}
        >
          <BankIdSweden
            fieldValue={signRequestData.ssn || null}
            fieldLabel={t("containers.sign.fields.ssnSE.label")}
            fieldPlaceholder={t("containers.sign.fields.ssnSE.placeholder")}
            buttonSignLabel={t("containers.sign.buttons.signWith", {
              method: methodLabel,
            })}
            buttonCancelLabel={t("containers.sign.buttons.cancel")}
            onSign={onSign}
            onCancel={onCancel}
          />
        </StatusContainer>
      )}
      {method === SignMethods.FREJAEID && (
        <StatusContainer
          hasStarted={signingState.customState.signStatus !== null}
          isSignSuccess={isSignSuccessful(signingState.customState.signStatus)}
          title={signTitles.title}
          description={signTitles.description}
          buttonContinueLabel={t("containers.sign.buttons.continue")}
          buttonResetLabel={t("containers.sign.buttons.tryAgain")}
          onContinue={onContinue}
          onReset={onReset}
        >
          <FrejaEId
            fieldValue={signRequestData.ssn || null}
            fieldLabel={t("containers.sign.fields.ssnSE.label")}
            fieldPlaceholder={t("containers.sign.fields.ssnSE.placeholder")}
            buttonSignLabel={t("containers.sign.buttons.signWith", {
              method: methodLabel,
            })}
            buttonCancelLabel={t("containers.sign.buttons.cancel")}
            onSign={onSign}
            onCancel={onCancel}
          />
        </StatusContainer>
      )}
      {method === SignMethods.BANKIDNO && (
        <StatusContainer
          hasStarted={signingState.customState.signStatus !== null}
          isSignSuccess={isSignSuccessful(signingState.customState.signStatus)}
          title={signTitles.title}
          description={signTitles.description}
          buttonContinueLabel={t("containers.sign.buttons.continue")}
          buttonResetLabel={t("containers.sign.buttons.tryAgain")}
          onContinue={onContinue}
          onReset={onReset}
          redirectUrl={
            signingState.actionState.sign.payload.operationToken?.redirect_url
          }
        >
          <BankIdNorway
            buttonSignLabel={t("containers.sign.buttons.signWith", {
              method: methodLabel,
            })}
            buttonCancelLabel={t("containers.sign.buttons.cancel")}
            loading={signingState.actionState.sign.meta.isLoading}
            disabled={signingState.actionState.sign.meta.isSuccess}
            onSign={onSign}
            onCancel={onCancel}
          />
        </StatusContainer>
      )}
      {method === SignMethods.HANDSIGN && (
        <StatusContainer
          hasStarted={signingState.customState.signStatus !== null}
          isSignSuccess={isSignSuccessful(signingState.customState.signStatus)}
          title={signTitles.title}
          description={signTitles.description}
          buttonContinueLabel={t("containers.sign.buttons.continue")}
          buttonResetLabel={t("containers.sign.buttons.tryAgain")}
          onContinue={onContinue}
          onReset={onReset}
        >
          <Handsign
            fieldFirstnameLabel={t("containers.sign.fields.firstName.label")}
            fieldLastnameLabel={t("containers.sign.fields.lastName.label")}
            fieldSignatureLabel={t("containers.sign.fields.signature.label")}
            fieldSignatureHelpLabel={t(
              "containers.sign.fields.signature.helpLabel"
            )}
            fieldSignatureOnEmptyLabel={t(
              "containers.sign.fields.signature.onEmptyLabel"
            )}
            buttonCancelLabel={t("containers.sign.buttons.cancel")}
            buttonClearLabel={t("containers.sign.buttons.clear")}
            buttonChooseSignatureLabel={t(
              "containers.sign.buttons.chooseSignature"
            )}
            buttonSignLabel={t("containers.sign.buttons.sign")}
            onSign={onSign}
            onCancel={onCancel}
          />
        </StatusContainer>
      )}
    </>
  );
};
