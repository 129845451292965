import React from "react";
import { useTranslation } from "react-i18next";
import { Alert } from "@mui/material";

import { FileViewerPDF } from "./FileViewerPDF";
import { FileViewerHTML } from "./FileViewerHTML";
import { config } from "config/config";

interface Props {
  uuid: string;
  filename: string;
}

export const FileViewer = ({ uuid, filename }: Props) => {
  const { t } = useTranslation();
  const height = "600px";
  const width = "100%";

  if (!filename) {
    return (
      <Alert severity="error">{t("components.fileviewer.fileNotFound")}</Alert>
    );
  }

  const ext = filename.split(".").pop();
  const src = `${config.backendUrl}/sign-request/${uuid}/file/`;

  switch (ext.toLowerCase()) {
    case "pdf":
      return <FileViewerPDF src={src} width={width} height={height} />;
    case "html":
      return <FileViewerHTML src={src} width={width} height={height} />;
    default:
      return <FileViewerHTML src={src} width={width} height={height} />;
  }
};
