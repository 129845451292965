import { createSlice } from "@reduxjs/toolkit";

import { config } from "config/config";
import { setTheme } from "./services";
import { ConfigState } from "./types";

export const initialState: ConfigState = {
  themeId: config.theme.darkTheme === true ? "dark" : "light",
};

export const reducers = createSlice({
  name: "config",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // setTheme
      .addCase(setTheme.fulfilled, (state, action) => {
        state.themeId = action.payload;
      });
  },
});

export default reducers.reducer;
