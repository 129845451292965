import React from "react";
import { Menu, MenuItem, Button } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

import i18n from "locale/i18n";
import { getLocalesList, getSelectedLocaleLabel } from "utils/locale";

export const LanguageMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (code) => {
    i18n.changeLanguage(code).then(() => handleClose());
  };

  const locales = getLocalesList();
  const selectedLocale = getSelectedLocaleLabel();

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        color="inherit"
        endIcon={<ExpandMore />}
        sx={{ textTransform: "capitalize" }}
      >
        {selectedLocale}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        disableScrollLock={true}
      >
        {locales.map((locale) => (
          <MenuItem
            key={locale.code}
            onClick={() => changeLanguage(locale.code)}
          >
            {locale.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
