import { useEffect, useRef } from "react";

import { config } from "config/config";
import { signStatusToIsSignSuccessMap } from "store/signing/constants";
import { SignMethod } from "./types";

export const useInterval = (callback: Function, delay?: number | null) => {
  const savedCallback = useRef<Function>();

  // remember the latest callback
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // set up the interval
  useEffect(() => {
    function tick() {
      if (savedCallback && savedCallback.current) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export const isSignSuccessful = (signStatus) => {
  if (signStatus === null) {
    return null;
  } else if (
    Object.prototype.hasOwnProperty.call(
      signStatusToIsSignSuccessMap,
      signStatus
    )
  ) {
    return signStatusToIsSignSuccessMap[signStatus];
  }
  return false;
};

/*export const getAppStatus = (signState, isSignSuccessful) => {
  if (signState.meta.isLoading === false) {
    if (isSignSuccessful === null) {
      return signStatuses.POLLING;
    } else if (isPollStatusSuccess === true) {
      return signStatuses.SIGN_SUCCESS;
    } else if (
      isPollStatusSuccess === false ||
      signState.meta.isSuccess === false
    ) {
      return signStatuses.SIGN_FAILED;
    }
  }
  return signStatuses.NOT_STARTED;
};*/

export const getPreferredSignMethod = (lng: string): SignMethod => {
  const keyExists = Object.prototype.hasOwnProperty.call(
    config.sign.preferredSignMethod,
    lng
  );
  if (keyExists) {
    return config.sign.preferredSignMethod[lng];
  } else {
    return null;
  }
};

export const getSignMethod = (
  preferredSignMethod: SignMethod,
  allowedMethods: SignMethod[]
): SignMethod => {
  if (allowedMethods.includes(preferredSignMethod)) {
    return preferredSignMethod;
  } else {
    return config.sign.fallbackSignMethod;
  }
};
