import { Typography } from "@mui/material";
import moment from "moment";

interface Props {
  timestamp: string;
}

export const Timestamp = ({ timestamp }: Props) => {
  return (
    <Typography
      variant="caption"
      display="block"
      sx={{
        mt: 4,
        p: 0,
        color: "secondary.contrastText",
        textAlign: "right",
        fontStyle: "italic",
      }}
    >
      {moment(timestamp).format("YYYY-MM-DD HH:mm")}
    </Typography>
  );
};
