import { Container, useTheme } from "@mui/material";
import * as yup from "yup";

import { FrejaEIdSymbol, FrejaEIdSymbolWhite } from "./logos";
import { LogoContainer } from "./subcomponents/LogoContainer";
import { SignForm } from "./subcomponents/SignForm";
import { isSwedishPersonnummer } from "./utils";
import { Button } from "app/components/buttons/Button";

interface Props {
  fieldValue: string;
  fieldLabel: string;
  fieldPlaceholder: string;
  buttonSignLabel: string;
  buttonCancelLabel: string;
  onSign: (values: any) => void;
  onCancel: (values: any) => void;
}

const schema = yup
  .object({
    id: yup.string().test("id", "Required", isSwedishPersonnummer),
  })
  .required();

export const FrejaEId = ({
  fieldValue,
  fieldLabel,
  fieldPlaceholder,
  buttonSignLabel,
  buttonCancelLabel,
  onSign,
  onCancel,
}: Props) => {
  const isLightTheme = useTheme().palette.mode === "light";
  return (
    <Container maxWidth="xs" sx={{ textAlign: "center" }}>
      <LogoContainer>
        {isLightTheme ? <FrejaEIdSymbol /> : <FrejaEIdSymbolWhite />}
      </LogoContainer>
      <SignForm
        fieldValue={fieldValue}
        fieldLabel={fieldLabel}
        fieldPlaceholder={fieldPlaceholder}
        buttonSignLabel={buttonSignLabel}
        onSign={onSign}
        schema={schema}
      />
      <Button label={buttonCancelLabel} onClick={onCancel} variant="outlined" />
    </Container>
  );
};
