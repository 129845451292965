import { configureStore } from "@reduxjs/toolkit";

import config from "./config/reducers";
import signing from "./signing/reducers";

export const store = configureStore({
  reducer: {
    config: config,
    signing: signing,
  },
});

export type AppDispatch = typeof store.dispatch;
