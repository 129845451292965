import React, { ReactChild } from "react";
import { Box, Typography } from "@mui/material";

interface Props {
  title: string;
  description?: string;
  icon: ReactChild;
}

export const Alert = ({ title, description, icon }: Props) => {
  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box>{icon}</Box>
      <Typography
        variant="h5"
        sx={{ color: "text.primary", fontWeight: "medium" }}
      >
        {title}
      </Typography>
      <Typography variant="subtitle1" sx={{ color: "text.primary" }}>
        <div
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      </Typography>
    </Box>
  );
};
