import { createSlice } from "@reduxjs/toolkit";

import { metaStates } from "store/states";
import { signStatuses } from "./constants";
import {
  reset,
  signRequestGet,
  signRequestStart,
  signRequestPoll,
  signRequestGetSignature,
} from "./services";
import { SigningState } from "./types";

export const initialState: SigningState = {
  customState: {
    signStatus: null,
  },
  actionState: {
    get: {
      meta: metaStates.initial,
      payload: null,
    },
    sign: {
      meta: metaStates.initial,
      payload: {
        message: null,
        statusCode: null,
        operationToken: null,
        qrCodeLink: null,
      },
    },
    poll: {
      meta: metaStates.initial,
      payload: {
        message: null,
        statusCode: null,
      },
    },
    getSignature: {
      meta: metaStates.initial,
      payload: {
        message: null,
      },
    },
  },
};

export const reducers = createSlice({
  name: "signing",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // reset
      .addCase(reset.fulfilled, (state) => {
        state.customState = initialState.customState;
        state.actionState.sign = initialState.actionState.sign;
        state.actionState.poll = initialState.actionState.poll;
      })
      // jwt
      .addCase(signRequestGet.pending, (state, action) => {
        state.actionState.get = {
          meta: metaStates.pending,
          payload: initialState.actionState.get.payload,
        };
      })
      .addCase(signRequestGet.fulfilled, (state, action) => {
        state.actionState.get = {
          meta: metaStates.success,
          payload: action.payload,
        };
      })
      .addCase(signRequestGet.rejected, (state, action) => {
        state.actionState.get = {
          meta: metaStates.failure,
          payload: action.payload || initialState.actionState.get.payload,
        };
      })
      // sign
      .addCase(signRequestStart.pending, (state) => {
        state.customState.signStatus = signStatuses.started;
        state.actionState.sign.meta = metaStates.pending;
      })
      .addCase(signRequestStart.fulfilled, (state, action) => {
        state.customState.signStatus = action.payload.statusCode;
        state.actionState.sign = {
          meta: metaStates.success,
          payload: action.payload,
        };
      })
      .addCase(signRequestStart.rejected, (state, action) => {
        state.customState.signStatus = action.payload.statusCode;
        state.actionState.sign = {
          meta: metaStates.failure,
          payload: action.payload || initialState.actionState.sign.payload,
        };
      })
      // poll
      .addCase(signRequestPoll.pending, (state, action) => {
        state.actionState.poll.meta = metaStates.pending;
      })
      .addCase(signRequestPoll.fulfilled, (state, action) => {
        state.customState.signStatus = action.payload.statusCode;
        state.actionState.poll = {
          meta: metaStates.success,
          payload: action.payload,
        };
      })
      .addCase(signRequestPoll.rejected, (state, action) => {
        state.customState.signStatus = action.payload.statusCode;
        state.actionState.poll = {
          meta: metaStates.failure,
          payload: action.payload || initialState.actionState.poll.payload,
        };
      })
      // signature
      .addCase(signRequestGetSignature.pending, (state, action) => {
        state.actionState.getSignature.meta = metaStates.pending;
      })
      .addCase(signRequestGetSignature.fulfilled, (state, action) => {
        state.customState.signStatus = signStatuses.complete;
        state.actionState.getSignature = {
          meta: metaStates.success,
          payload: action.payload,
        };
      })
      .addCase(signRequestGetSignature.rejected, (state, action) => {
        state.customState.signStatus = signStatuses.failed;
        state.actionState.getSignature = {
          meta: metaStates.failure,
          payload: action.payload || initialState.actionState.poll.payload,
        };
      });
  },
});

export default reducers.reducer;
