import { styled } from "@mui/material";

const IFrame = styled("iframe")();

interface Props {
  title: string;
  src: string;
  height: string;
  width: string;
  children?: any;
}

export const Frame = ({ title, src, height, width, children }: Props) => {
  return (
    <IFrame title={title} src={src} height={height} width={width}>
      {children}
    </IFrame>
  );
};
