import { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Alert } from "@mui/material";

import { SignMethod } from "app/containers/sign/types";
import { SignAppContainer } from "app/containers/sign/signapp/SignAppContainer";
import { signingSelector } from "store/signing/selectors";
import { ContentContainer } from "app/components/common/ContentContainer";
import { Tabs } from "app/components/tabs/Tabs";
import { resources } from "locale/i18n";
import { getPreferredSignMethod, getSignMethod } from "../utils";

interface Props {
  uuid: string;
  redirected: boolean;
  lng?: keyof typeof resources;
}

export const SignApp = ({ uuid, redirected, lng }: Props) => {
  const { t } = useTranslation();
  const signingState = useSelector(signingSelector);

  const allowedSignMethods = Object.keys(
    signingState.actionState.get.payload?.provider_config
  ).map((method) => method as SignMethod);

  const tabs = allowedSignMethods.map((method) => {
    return {
      id: method,
      label: t(`containers.sign.methods.${method}`),
    };
  });

  const preferredSignMethod = getPreferredSignMethod(lng);
  const defaultMethod = getSignMethod(preferredSignMethod, allowedSignMethods);
  const [method, setMethod] = useState<SignMethod>(defaultMethod);

  const clientConfig = signingState.actionState.get.payload.client_config;
  const signAppText = clientConfig?.signInfoText ?? "";

  return (
    <ContentContainer>
      {signAppText && (
        <Alert severity="info" sx={{ mb: 2 }}>
          {signAppText}
        </Alert>
      )}
      {signingState.customState.signStatus === null && (
        <Tabs tabs={tabs} selectedTab={method} setTab={setMethod} />
      )}
      <SignAppContainer method={method} uuid={uuid} redirected={redirected} />
    </ContentContainer>
  );
};
