import { Box } from "@mui/system";
import { useState } from "react";
import axios from "axios";

interface Props {
  src: string;
  width: string;
  height: string;
  scrollable?: boolean;
}

export const FileViewerHTML = ({ src, width, height, scrollable }: Props) => {
  const [content, setContent] = useState(null);

  const scrollableSx = scrollable
    ? {
        width: width,
        height: height,
        maxHeight: "400px",
        overflowY: "scroll",
      }
    : {};

  axios.get(src).then((r) => setContent(r.data));

  return (
    <Box
      sx={{
        "& div *": {
          m: 0,
        },
        ...scrollableSx,
      }}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    </Box>
  );
};
