import { Box, Typography } from "@mui/material";

interface Props {
  title: string;
  children: any;
}

export const FieldWrapper = ({ title, children }: Props) => {
  return (
    <Box sx={{ textAlign: "left", mt: 2 }}>
      <Typography variant="subtitle1" sx={{ fontWeight: 400 }}>
        {title}
      </Typography>
      {children}
    </Box>
  );
};
