import { AppBar, Toolbar } from "@mui/material";

import { isLocalhost } from "utils/url";
import { LanguageMenu } from "../menus/LanguageMenu";
import { MainMenu } from "../menus/MainMenu";
import { ThemeTogglerMenu } from "../menus/ThemeTogglerMenu";

export const Header = () => {
  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar>
        <MainMenu />
        {isLocalhost() && <ThemeTogglerMenu />}
        <LanguageMenu />
      </Toolbar>
    </AppBar>
  );
};
