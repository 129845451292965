import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";

import { configSelector } from "store/config/selectors";
import { setTheme } from "store/config/services";

export const ThemeTogglerMenu = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const config = useSelector(configSelector);

  const onClick = () => {
    const newTheme = config.themeId === "light" ? "dark" : "light";
    dispatch(setTheme(newTheme));
  };

  return (
    <IconButton onClick={onClick} color="inherit">
      {theme.palette.mode === "dark" ? (
        <Brightness7Icon />
      ) : (
        <Brightness4Icon />
      )}
    </IconButton>
  );
};
