import { Box } from "@mui/material";
import { ReactChild } from "react";

interface Props {
  children: ReactChild | ReactChild[];
}

export const LogoContainer = ({ children }: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {children}
    </Box>
  );
};
