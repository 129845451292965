export const ApiHeaders = {
  base: {
    headers: {
      Accept: "application/json",
      "Accept-Language": "en-US",
    },
  },
  json: {
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      "Accept-Language": "en-US",
    },
  },
  urlEncoded: {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
      "Accept-Language": "en-US",
    },
  },
};
