import { SignMethods } from "app/containers/sign/types";
import { Languages } from "locale/constants";
import { SiteConfig } from "./types";
import { getTheme, getThemeId, getPublicPath } from "./utils";

export const defaultFallbackThemeId = "nordkyc";
export const defaultFallbackLng = "sv";

const getConfig = () => {
  const runtimeConfig = window.__RUNTIME_CONFIG__;
  const themeId = getThemeId(defaultFallbackThemeId);
  const theme = getTheme(themeId);

  const config = {
    backendUrl: runtimeConfig.backendUrl.replace(/\/$/, ""),
    theme: {
      id: theme.id || null,
      siteName: theme.siteName || null,
      siteTitle: theme.siteTitle || null,
      imageFaviconPath: getPublicPath(theme.imageFaviconPath) || null,
      imageLogoPath: getPublicPath(theme.imageLogoPath) || null,
      imageBackgroundPath: getPublicPath(theme.imageBackgroundPath) || null,
      palettePrimaryColor: theme.palettePrimaryColor || null,
      fonts: theme.fonts || null,
      darkTheme: theme.darkTheme || false,
      useInvertedSignFlow: theme.useInvertedSignFlow || false,
      defaultLng: theme.defaultLng || defaultFallbackLng,
    },
    sign: {
      preferredSignMethod: {
        [Languages.SV]: SignMethods.BANKID,
        [Languages.DA]: SignMethods.HANDSIGN,
        [Languages.NO]: SignMethods.HANDSIGN,
        [Languages.EN]: SignMethods.HANDSIGN,
      },
      fallbackSignMethod: SignMethods.HANDSIGN,
    },
  } as SiteConfig;

  return config;
};

/* config */

export const config = getConfig();
