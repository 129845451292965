export const getThemeId = (defaultThemeId: string): string => {
  const runtimeConfig = window.__RUNTIME_CONFIG__;
  const hostname = window.location.hostname;
  let themeId = defaultThemeId;
  Object.keys(runtimeConfig.hostnameThemeMap).forEach((key) => {
    if (hostname.includes(key)) {
      themeId = runtimeConfig.hostnameThemeMap[key];
    }
  });
  return themeId;
};

export const getTheme = (themeId: string) => {
  const runtimeConfig = window.__RUNTIME_CONFIG__;
  const themes = runtimeConfig.themes;
  return themes.filter((theme) => {
    return theme.id === themeId;
  })[0];
};

export const getPublicPath = (path?: string): string => {
  return path ? `${process.env.PUBLIC_URL}/${path}` : null;
};
