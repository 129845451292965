import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledButton = styled(Button)<any>(({ theme }) => ({
  boxShadow: "none",
  "&:hover": {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const TabButtonShadow = ({ label, onClick }) => {
  return (
    <StyledButton
      onClick={onClick}
      variant="text"
      sx={{
        flex: 1,
        textTransform: "none",
        color: "text.primary",
        bgcolor: "secondary.main",
        borderRadius: 5,
        m: 0.2,
        boxShadow: 2,
      }}
    >
      {label}
    </StyledButton>
  );
};

const TabButtonBorderless = ({ label, onClick }) => {
  return (
    <Button
      onClick={onClick}
      variant="text"
      sx={{
        flex: 1,
        textTransform: "none",
        color: "text.primary",
        borderRadius: 5,
        m: 0.2,
      }}
    >
      {label}
    </Button>
  );
};

export const TabButton = ({ selected, label, onClick }) => {
  return (
    <>
      {selected ? (
        <TabButtonShadow label={label} onClick={onClick} />
      ) : (
        <TabButtonBorderless label={label} onClick={onClick} />
      )}
    </>
  );
};
