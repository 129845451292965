import { Button, Box } from "@mui/material";

import { config } from "config/config";

export const MainMenu = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        color="inherit"
        sx={{ textTransform: "capitalize" }}
      >
        {config.theme.imageLogoPath !== null ? (
          <img
            src={config.theme.imageLogoPath}
            alt={config.theme.siteName}
            width={128}
          />
        ) : (
          config.theme.siteName
        )}
      </Button>
    </Box>
  );
};
