import { Container, useTheme } from "@mui/material";

import { Button } from "app/components/buttons/Button";
import { BankIdNoLogo, BankIdNoLogoWhite } from "./logos";
import { LogoContainer } from "./subcomponents/LogoContainer";

interface Props {
  buttonSignLabel: string;
  buttonCancelLabel: string;
  loading: boolean;
  disabled: boolean;
  onSign: (values: any) => void;
  onCancel: (values: any) => void;
}

export const BankIdNorway = ({
  buttonSignLabel,
  buttonCancelLabel,
  loading,
  disabled,
  onSign,
  onCancel,
}: Props) => {
  const isLightTheme = useTheme().palette.mode === "light";
  return (
    <Container maxWidth="xs" sx={{ textAlign: "center" }}>
      <>
        <LogoContainer>
          {isLightTheme ? <BankIdNoLogo /> : <BankIdNoLogoWhite />}
        </LogoContainer>
        <Button
          label={buttonSignLabel}
          onClick={onSign}
          loading={loading}
          disabled={disabled}
        />
        <Button
          label={buttonCancelLabel}
          onClick={onCancel}
          variant="outlined"
        />
      </>
    </Container>
  );
};

/*const isBankIdNOUserID = (value: string) => {
  return true;
  
  if (value.length !== 11) {
    return false;
  }
  var regex = new RegExp("^[0-9]+$");
  return regex.test(value);
  
};*/
