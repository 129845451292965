import { Container, Typography } from "@mui/material";

interface Props {
  title: string;
  description?: string;
}

export const Title = ({ title, description }: Props) => {
  return (
    <Container sx={{ mb: "20px" }}>
      <Typography variant="h3">{title}</Typography>
      {description && <Typography variant="h6">{description}</Typography>}
    </Container>
  );
};
