import { Box, Toolbar, ThemeProvider, CssBaseline } from "@mui/material";
import { useSelector } from "react-redux";
import { ReactChild, ReactChildren } from "react";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";

import { Header } from "./common/Header";
import { createSiteTheme } from "config/theme";
import { configSelector } from "store/config/selectors";
import { config } from "config/config";

interface Props {
  children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
}

export const Wrapper = ({ children }: Props) => {
  const { t } = useTranslation();

  document.title = t("general.siteTitle")
    ? `${config.theme.siteTitle} - ${t("general.siteTitle")}`
    : config.theme.siteTitle;
  if (config.theme.imageFaviconPath) {
    document
      .getElementById("favicon")
      .setAttribute("href", config.theme.imageFaviconPath);
  }

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const embedded = params.get("embed");

  const configState = useSelector(configSelector);
  const siteTheme = createSiteTheme(configState.themeId);

  return (
    <ThemeProvider theme={siteTheme}>
      <CssBaseline />
      {embedded ? (
        <Box>
          <main>
            <Box sx={{ m: 3 }}>{children}</Box>
          </main>
        </Box>
      ) : (
        <Box
          sx={
            config.theme.imageBackgroundPath
              ? {
                  minHeight: "100vh",
                  background: `url(${config.theme.imageBackgroundPath}) no-repeat center center fixed`,
                  backgroundSize: "cover",
                  WebkitBackgroundSize: "cover",
                  MozBackgroundSize: "cover",
                  OBackgroundSIze: "cover",
                }
              : {}
          }
        >
          <main>
            <Header />
            <Box>
              <Toolbar />
              <Box sx={{ p: 3 }}>{children}</Box>
            </Box>
          </main>
        </Box>
      )}
    </ThemeProvider>
  );
};
