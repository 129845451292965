import i18n from "i18next";
import ICU from "i18next-icu";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import no from "locale/translations/no.json";
import sv from "locale/translations/sv.json";
import en from "locale/translations/en.json";
import da from "locale/translations/da.json";
import { config } from "config/config";

export const resources = {
  sv: { translation: sv, label: "Swedish" },
  en: { translation: en, label: "English" },
  no: { translation: no, label: "Norwegian" },
  da: { translation: da, label: "Danish" },
};

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(ICU)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    detection: {
      order: ["querystring"],
      lookupQuerystring: "lng",
    },
    fallbackLng: config.theme.defaultLng,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
