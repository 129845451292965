import { Box } from "@mui/material";

import { TabButton } from "./TabButton";

interface Props {
  tabs: Tab[];
  selectedTab: any;
  setTab: any;
}

interface Tab {
  id: string;
  label: string;
}

export const Tabs = ({ tabs, selectedTab, setTab }: Props) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          p: 0.5,
          m: 0,
          borderRadius: 10,
          border: 2,
          borderColor: "secondary.light",
        }}
      >
        {tabs.map((tab, i) => (
          <TabButton
            key={`tab-${tab.id}-${i}`}
            onClick={() => setTab(tab.id)}
            label={tab.label}
            selected={selectedTab === tab.id}
          />
        ))}
      </Box>
    </>
  );
};
