import BankIdSeLogoImage from "./bankid/logo.svg";
import BankIdSeLogoWhiteImage from "./bankid/logo_white.svg";
import BankIdNoLogoImage from "./bankidno/logo.svg";
import BankIdNoLogoWhiteImage from "./bankidno/logo_white.svg";
import FrejaEIdLogoImage from "./frejaeid/logo.svg";
import FrejaEIdLogoWhiteImage from "./frejaeid/logo_white.svg";
import FrejaEIdSymbolImage from "./frejaeid/symbol.svg";
import FrejaEIdSymbolWhiteImage from "./frejaeid/symbol_white.svg";
import MitIdLogoImage from "./mitid/logo.svg";
import MitIdLogoWhiteImage from "./mitid/logo_white.svg";

const Image = ({ src }) => {
  return <img src={src} alt="" width="128" height="128" />;
};

export const BankIdSeLogo = () => <Image src={BankIdSeLogoImage} />;
export const BankIdSeLogoWhite = () => <Image src={BankIdSeLogoWhiteImage} />;
export const BankIdNoLogo = () => <Image src={BankIdNoLogoImage} />;
export const BankIdNoLogoWhite = () => <Image src={BankIdNoLogoWhiteImage} />;
export const FrejaEIdLogo = () => <Image src={FrejaEIdLogoImage} />;
export const FrejaEIdLogoWhite = () => <Image src={FrejaEIdLogoWhiteImage} />;
export const FrejaEIdSymbol = () => <Image src={FrejaEIdSymbolImage} />;
export const FrejaEIdSymbolWhite = () => (
  <Image src={FrejaEIdSymbolWhiteImage} />
);
export const MitIdLogo = () => <Image src={MitIdLogoImage} />;
export const MitIdLogoWhite = () => <Image src={MitIdLogoWhiteImage} />;
