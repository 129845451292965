import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

import { SignApp } from "app/containers/sign/signapp";
import { Document } from "app/containers/sign/document";
import { signingGetSelector } from "store/signing/selectors";
import { signRequestGet } from "store/signing/services";
import { AlertError } from "app/components/alerts/AlertError";
import { AlertPending } from "app/components/alerts/AlertPending";
import { Wrapper } from "app/components/Wrapper";
import { ContentContainer } from "app/components/common/ContentContainer";
import { config } from "config/config";
import { resources } from "locale/i18n";

export const Index = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();

  const signGetState = useSelector(signingGetSelector);

  // get params
  const params = new URLSearchParams(location.search);
  const uuid = params.get("uuid");
  const lng = params.get("lng") || config.theme.defaultLng;
  const redirected = params.get("redirected") === "true";

  // get jwt from uuid
  useEffect(() => {
    if (uuid) {
      const data = {
        uuid: uuid,
      };
      dispatch(signRequestGet(data));
    }
  }, [dispatch, uuid]);

  const getContent = () => {
    if (uuid) {
      if (signGetState.meta.isLoading) {
        return (
          <ContentContainer>
            <AlertPending title={t("containers.sign.titles.onJwtPending")} />
          </ContentContainer>
        );
      } else {
        if (signGetState.meta.isSuccess) {
          if (signGetState.payload.is_completed) {
            return (
              <ContentContainer>
                <AlertError
                  title={t("containers.sign.titles.signAlreadyCompleted")}
                />
              </ContentContainer>
            );
          } else {
            return (
              <>
                <Document
                  uuid={uuid}
                  filename={signGetState.payload.document_to_sign.filename}
                  timestamp={signGetState.payload.created_at}
                />
                <SignApp
                  uuid={uuid}
                  redirected={redirected}
                  lng={lng as keyof typeof resources}
                />
              </>
            );
          }
        }
      }
    }
    return (
      <ContentContainer>
        <AlertError title={t("containers.sign.titles.onJwtFail")} />
      </ContentContainer>
    );
  };

  const flexDirection = config.theme.useInvertedSignFlow
    ? "column-reverse"
    : "column";

  return (
    <Wrapper>
      <Container
        maxWidth="md"
        sx={{
          display: "flex",
          flexDirection: flexDirection,
          justifyContent: "center",
        }}
      >
        {getContent()}
      </Container>
    </Wrapper>
  );
};
