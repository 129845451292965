import { Box } from "@mui/material";
import { isMobile } from "react-device-detect";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { signingSelector } from "../../../../store/signing/selectors";

interface Props {
  qrCodeLink: string;
  shutdown: boolean;
}

export const QRCode = ({ qrCodeLink, shutdown }: Props) => {
  const [timer, setTimer] = useState(0);
  const [done, setDone] = useState(false);
  const idName = "bankIdQr";
  const ref = useRef(null);
  const [currentImage, setCurrentImage] = useState(qrCodeLink);
  const signingState = useSelector(signingSelector);

  useEffect(() => {
    if (signingState.customState.signStatus === "USER_SIGN") {
      setDone(true);
      clearInterval(ref.current);
    }
    const preloadImage = async (imageUrl) => {
      try {
        const image = new Image();
        image.src = imageUrl;

        await new Promise((resolve, reject) => {
          image.onload = resolve;
          image.onerror = () => reject(new Error("Image load error"));
        });

        return imageUrl;
      } catch (error) {
        console.error("Error preloading image:", error);
        return null;
      }
    };

    const fetchNewImage = async () => {
      const imageUrlObject = await preloadImage(qrCodeLink);
      if (imageUrlObject) {
        setCurrentImage(imageUrlObject);
        (document.getElementById(idName) as HTMLImageElement).src =
          (document.getElementById(idName) as HTMLImageElement).src +
          `&timestamp=${new Date().getTime()}`;
      } else {
        console.error("Image could not be preloaded");
      }
    };

    ref.current = setInterval(() => {
      fetchNewImage();
      setTimer((val) => val + 1);
    }, 1000);

    return () => clearInterval(ref.current);
  }, []);

  useEffect(() => {
    if (timer > 25 || shutdown) {
      setDone(true);
      clearInterval(ref.current);
    }
  }, [timer]);

  if (!qrCodeLink || shutdown || done) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: shutdown ? "0%" : "100%",
        transform: isMobile ? "scale(0.6)" : "scale(0.8)",
      }}
    >
      <img
        src={currentImage}
        id={idName}
        style={{
          minHeight: "400px",
          minWidth: "400px",
          margin: "0rem 0rem 0rem 0rem",
          border: "none",
        }}
        alt="QR Code"
      />
    </Box>
  );
};
