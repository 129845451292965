import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  SignRequestGetRequest,
  SignRequestGetResponse,
  SignRequestStartRequest,
  SignRequestStartResponse,
  SignRequestPollRequest,
  SignRequestPollResponse,
  SignRequestSignatureResponse,
  SignRequestSignatureRequest,
} from "./types";
import { Api } from "store/api";
import { Routes } from "../routes";
import { ApiHeaders } from "store/headers";

const serviceName = "signing";

export const reset = createAsyncThunk(
  `${serviceName}/reset`,
  (data, thunkAPI) => {
    return null;
  }
);

export const signRequestGet = createAsyncThunk<
  SignRequestGetResponse,
  SignRequestGetRequest,
  { rejectValue: SignRequestGetResponse }
>(`${serviceName}/sign-request/{uuid}`, async (data, thunkAPI) => {
  const response = await Api.backend.get(
    Routes.backend.signRequest(data.uuid),
    null,
    ApiHeaders.base
  );
  const responseData = response.data as SignRequestGetResponse;
  if (response.ok) {
    return responseData;
  } else {
    return thunkAPI.rejectWithValue(responseData);
  }
});

export const signRequestStart = createAsyncThunk<
  SignRequestStartResponse,
  SignRequestStartRequest,
  { rejectValue: SignRequestStartResponse }
>(`${serviceName}/sign-request/{uuid}/start`, async (data, thunkAPI) => {
  const response = await Api.backend.post(
    Routes.backend.signRequestStart(data.params.uuid),
    data.body,
    ApiHeaders.base
  );
  const responseData = response.data as SignRequestStartResponse;
  if (response.ok) {
    return responseData;
  } else {
    return thunkAPI.rejectWithValue(responseData);
  }
});

export const signRequestPoll = createAsyncThunk<
  SignRequestPollResponse,
  SignRequestPollRequest,
  { rejectValue: SignRequestPollResponse }
>(
  `${serviceName}/sign-request/{uuid}/poll/{orderRef}`,
  async (data, thunkAPI) => {
    const response = await Api.backend.post(
      Routes.backend.signRequestPoll(data.uuid, data.orderRef),
      null,
      ApiHeaders.base
    );
    const responseData = response.data as SignRequestPollResponse;
    if (response.ok) {
      return responseData;
    } else {
      return thunkAPI.rejectWithValue(responseData);
    }
  }
);

export const signRequestGetSignature = createAsyncThunk<
  SignRequestSignatureResponse,
  SignRequestSignatureRequest,
  { rejectValue: SignRequestSignatureResponse }
>(`${serviceName}/sign-request/{uuid}/signature`, async (data, thunkAPI) => {
  const response = await Api.backend.get(
    Routes.backend.signRequestSignature(data.uuid),
    null,
    ApiHeaders.base
  );
  const responseData = response.data as SignRequestSignatureResponse;
  if (response.ok) {
    return responseData;
  } else {
    return thunkAPI.rejectWithValue(responseData);
  }
});
