import { ContentContainer } from "app/components/common/ContentContainer";
import { FileViewer } from "app/components/fileviewer";
import { Timestamp } from "./Timestamp";

interface Props {
  uuid: string;
  filename?: string;
  timestamp?: string;
}

export const Document = ({ uuid, filename, timestamp }: Props) => {
  return (
    <ContentContainer>
      <FileViewer uuid={uuid} filename={filename} />
      <Timestamp timestamp={timestamp} />
    </ContentContainer>
  );
};
