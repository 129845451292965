import { MetaStates } from "./types";

export const metaStates: MetaStates = {
  initial: {
    isLoading: null,
    isSuccess: null,
  },
  pending: {
    isLoading: true,
    isSuccess: null,
  },
  success: {
    isLoading: false,
    isSuccess: true,
  },
  failure: {
    isLoading: false,
    isSuccess: false,
  },
};
