import React from "react";
import { ErrorOutlineOutlined } from "@mui/icons-material";

import { Alert } from "./Alert";

interface Props {
  title: string;
  description?: string;
}

export const AlertError = ({ title, description }: Props) => {
  return (
    <Alert
      title={title}
      description={description}
      icon={
        <ErrorOutlineOutlined sx={{ color: "text.primary", fontSize: 60 }} />
      }
    />
  );
};
