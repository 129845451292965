import { Controller, useForm } from "react-hook-form";
import { Grid } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";

import { StyledTextField } from "./StyledTextField";
import { Button } from "app/components/buttons/Button";
import { FieldWrapper } from "./FieldWrapper";

interface Props {
  fields: Field[];
  buttonSignLabel: string;
  onSign: (values: any) => void;
  isSignDisabled: boolean;
  schema: any;
  children: any;
}

interface Field {
  id: string;
  label: string;
  placeholder: string | null;
}

export const SignFormSignature = ({
  fields,
  buttonSignLabel,
  onSign,
  isSignDisabled,
  schema,
  children,
}: Props) => {
  const { handleSubmit, control } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    // defaultValues: {},
    resolver: yupResolver(schema),
  });

  return (
    <form onSubmit={handleSubmit(onSign)}>
      <Grid container spacing={2}>
        {fields.map((f) => (
          <Grid item xs={6} key={`form-field-${f.id}`}>
            <Controller
              name={f.id}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FieldWrapper title={f.label}>
                  <StyledTextField
                    id={f.id}
                    type="text"
                    value={value ? value : ""}
                    onChange={onChange}
                    error={!!error}
                    margin="dense"
                    placeholder={f.placeholder}
                    fullWidth
                  />
                </FieldWrapper>
              )}
            />
          </Grid>
        ))}
      </Grid>
      {children}
      <Button label={buttonSignLabel} type="submit" disabled={isSignDisabled} />
    </form>
  );
};
