import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "@mui/material";

import { Wrapper } from "app/components/Wrapper";
import { Title } from "app/components/typography/Title";
import { ContentContainer } from "app/components/common/ContentContainer";

export const Index = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Container maxWidth="md">
        <ContentContainer>
          <Title
            title={t("containers.index.notFound.title")}
            description={t("containers.index.notFound.description")}
          />
        </ContentContainer>
      </Container>
    </Wrapper>
  );
};
