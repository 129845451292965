export const Routes = {
  backend: {
    signRequest: (extRef: string) => `/sign-request/${extRef}`,
    signRequestStart: (extRef: string) => `/sign-request/${extRef}/start`,
    signRequestPoll: (extRef: string, orderRef: string) =>
      `/sign-request/${extRef}/poll/${orderRef}`,
    signRequestSignature: (extRef: string) =>
      `/sign-request/${extRef}/signature`,
  },
};
